define("mgw/router", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('logout');
    this.route('guest');
    this.route('reset-password');
    this.route('mfa-challenge');
    this.route('invalid-existing-password');
    this.route('user-profile');
    this.route('password-changed');
    this.route('change-password');
    this.route('mfa-setup');
    this.route('mfa-remove');
    this.route('new-project', function () {
      this.route('created', {
        path: '/:project_id'
      });
    });
    this.route('customers', function () {
      this.route('customer', {
        path: '/customer/:customer_id'
      });
    });
    this.route('projects', function () {
      this.route('project', {
        path: '/project/:project_id'
      });
    });
    this.route('users');
    this.route('dashboard');
    this.route('projects-list');
    this.route('project-widgets', {
      path: '/widgets'
    });
    this.route('project-templates', {
      path: '/templates'
    });
    this.route('federation-callback', {
      path: '/federation/callback'
    });
    this.route('ad-import');
    this.route('delete-user');
  });
  var _default = Router;
  _exports.default = _default;
});