define("mgw/components/edit-customer-profile", ["exports", "mgw/components/popover-base", "mgw/misc/utils"], function (_exports, _popoverBase, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    multiItem: Ember.computed.gt('data.items.length', 1),
    // Input
    items: Ember.computed.alias('data.items'),
    organizations: Ember.computed.alias('data.organizations'),
    projects: Ember.computed.alias('data.projects'),
    // Internal
    columnSettingsKey: 'userProjectsGridColumns',
    projectsTableData: null,
    projectsTableColumns: null,
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.setup);
      window.enableJqueryDrag();
    },
    setup: function setup() {
      var _this = this;

      var projectRows = [];
      this.get('projects').forEach(function (project) {
        var record;

        if (_this.get('multiItem')) {
          var commonRole = null;
          var commonSSOProviders = null;
          var commonExpDate = null;

          _this.get('items').forEach(function (item, idx) {
            var projMember = item.projects[project.get('id')];
            var privilegeLevel = item.customerAdmin ? _utils.default.privilegeLevels.customerAdmin : (projMember === null || projMember === void 0 ? void 0 : projMember.get('privilegeLevel')) || null;
            var ssoProviders = (projMember === null || projMember === void 0 ? void 0 : projMember.get('ssoProvidersString')) || null;
            var expirationDate = (projMember === null || projMember === void 0 ? void 0 : projMember.get('privilegeLevel')) !== _utils.default.privilegeLevels.guest ? null : (projMember === null || projMember === void 0 ? void 0 : projMember.get('user.expirationDate')) || null;

            if (!idx) {
              commonRole = privilegeLevel;
              commonSSOProviders = ssoProviders;
              commonExpDate = expirationDate;
            } else {
              if (commonRole !== 'multiplevalues' && privilegeLevel !== commonRole) commonRole = 'multiplevalues';
              if (commonSSOProviders !== 'multiplevalues' && ssoProviders !== commonSSOProviders) commonSSOProviders = 'multiplevalues';
              if (commonExpDate !== 'multiplevalues' && expirationDate !== commonExpDate) commonExpDate = 'multiplevalues';
            }
          });

          if (commonRole !== null) {
            record = Ember.Object.create({
              privilegeLevel: commonRole,
              ssoProvidersString: commonSSOProviders,
              user: {
                expirationDate: commonExpDate
              },
              project: {
                id: project.get('id')
              }
            });
          }
        } else {
          record = _this.get('items.0').projects[project.get('id')];

          if (_this.get('items.0').customerAdmin && !record) {
            record = Ember.Object.create({
              privilegeLevel: _utils.default.privilegeLevels.customerAdmin,
              ssoProvidersString: '',
              user: _this.get('items.0').user
            });
          }
        }

        projectRows.push({
          id: project.get('id'),
          defaultSortKey: project.get('name'),
          projectName: project.get('name'),
          record: record
        });
      });
      this.set('projectsTableData', projectRows);
      this.set('projectsTableColumns', [{
        id: "projectName",
        field: "projectName",
        name: this.intl.t('project'),
        width: 200,
        sortable: true
      }, {
        id: "role",
        field: "privilegeLevel",
        name: this.intl.t('role'),
        formatter: this.privilegeLevelFormatter.bind(this),
        width: 150,
        sortable: true,
        type: 'number'
      }, {
        id: "expirationDate",
        field: "user.expirationDate",
        name: this.intl.t('expirationDate'),
        formatter: this.expirationDateFormatter.bind(this),
        width: 150,
        sortable: true,
        type: 'date'
      }, {
        id: "ssoProviders",
        field: "ssoProvidersString",
        name: this.intl.t('ssoProviders'),
        formatter: this.ssoProvidersFormatter.bind(this),
        width: 200,
        sortable: true
      }]);
    },
    privilegeLevelFormatter: function privilegeLevelFormatter(row, cell, value, columnDef, dataContext) {
      var id = "project-row".concat(row, "cell").concat(cell);
      var record = dataContext.record;

      if (!record) {
        return "<span id=".concat(id, " class=\"invite-btn\"></span>");
      } else {
        var privilegeLevelText;

        if (record.get('privilegeLevel') == 'multiplevalues') {
          privilegeLevelText = this.intl.t(record.get('privilegeLevel'));
        } else {
          privilegeLevelText = this.intl.t(_utils.default.getPrivilegeLevelKeyText(record.get('privilegeLevel')));
        }

        return "<span id=".concat(id, " class=\"privilege-btn privilege-").concat(record.get('privilegeLevel'), "\">").concat(privilegeLevelText, "</span>");
      }
    },
    expirationDateFormatter: function expirationDateFormatter(row, cell, value, columnDef, dataContext) {
      var id = "project-row".concat(row, "cell").concat(cell);
      var record = dataContext.record;

      if (!this.get('multiItem') && (!record || record.get('privilegeLevel') !== 5) || !(record === null || record === void 0 ? void 0 : record.get('user.expirationDate'))) {
        return "";
      } else {
        var expirationDate = record.get('user.expirationDate') == 'multiplevalues' ? this.intl.t(record.get('user.expirationDate')) : window.moment(record.get('user.expirationDate')).format('YYYY-MM-DD');
        return "<span id=".concat(id, " class=\"expdate-btn\">").concat(expirationDate, "</span>");
      }
    },
    ssoProvidersFormatter: function ssoProvidersFormatter(row, cell, value, columnDef, dataContext) {
      var id = "project-row".concat(row, "cell").concat(cell);
      var record = dataContext.record;

      if (!record) {
        return "";
      } else {
        var ssoProvidersText = record.get('ssoProvidersString') == 'multiplevalues' ? this.intl.t(record.get('ssoProvidersString')) : record.get('ssoProvidersString') || '-';
        return "<span id=".concat(id, " class=\"ssoprovider-btn\">").concat(ssoProvidersText, "</span>");
      }
    },
    actions: {
      submit: function submit() {
        var _this2 = this;

        this.set('loading', true);
        var promises = [];
        this.get('items').forEach(function (userData) {
          var newProfile = !userData.customerProfile.get('type');
          var finalProfile;

          if (newProfile) {
            finalProfile = _this2.store.createRecord('customerUserProfile', userData.customerProfile);
            userData.customerProfile = finalProfile;
          } else {
            finalProfile = userData.customerProfile;
          }

          if (newProfile || finalProfile.get('hasDirtyAttributes')) promises.push(finalProfile.save()); // Now lets save all possible changes on project-member records

          for (var projectId in userData.projects) {
            var memberRecord = userData.projects[projectId];

            if (memberRecord === null || memberRecord === void 0 ? void 0 : memberRecord.get('hasDirtyAttributes')) {
              promises.push(memberRecord.save());
            }
          } // Also lets check if user has changed the expiration datre


          if (userData.user.get('hasDirtyAttributes')) {
            promises.push((userData.user.content || userData.user).save());
          }
        });
        Promise.all(promises).then(function () {
          if (_this2.get('callback')) _this2.get('delegate').send(_this2.get('callback'));

          _this2.set('loading', false);

          _this2.send('close');

          return false;
        }).catch(function (err) {
          console.warn('Error updating user: ' + err);

          _this2.set('loading', false);
        });
      },
      cancel: function cancel() {
        this.get('items').forEach(function (userData) {
          var newProfile = !userData.customerProfile.get('type');

          if (newProfile) {
            userData.customerProfile.set('firstName', null);
            userData.customerProfile.set('lastName', null);
            userData.customerProfile.set('title', null);
            userData.customerProfile.set('phone', null);
            userData.customerProfile.set('userOrganization', null);
          } else {
            if (userData.customerProfile.get('hasDirtyAttributes')) userData.customerProfile.rollbackAttributes();
          } // Now lets save all possible changes on project-member records


          for (var projectId in userData.projects) {
            var memberRecord = userData.projects[projectId];

            if (memberRecord === null || memberRecord === void 0 ? void 0 : memberRecord.get('hasDirtyAttributes')) {
              memberRecord.rollbackAttributes();
            }
          } // Also lets check if user has changed the expiration datre


          if (userData.user.get('hasDirtyAttributes')) {
            (userData.user.content || userData.user).rollbackAttributes();
          }
        });
        this.send('close');
        return false;
      },
      moreBtn: function moreBtn(sender) {
        var disableDelete = this.get('items').any(function (item) {
          return item.customerAdmin;
        });
        var items = [];
        items.addObject({
          name: this.intl.t('setRoleForAllProjects'),
          value: 'selectRole'
        });
        items.addObject({
          name: this.intl.t('removeFromAllProjects'),
          value: 'removeSelectedFromProjects',
          color: 'red'
        });
        items.addObject({
          name: this.intl.t('deleteUser'),
          value: 'deleteSelected',
          color: 'red',
          disabled: disableDelete
        });
        this.get('popoverservice').openPopover({
          name: 'select-item',
          data: items,
          callback: 'selectedMenuOption',
          delegate: this,
          popoverClass: 'popover--floating',
          sender: sender
        });
        return false;
      },
      selectedMenuOption: function selectedMenuOption(value) {
        this.send(value);
        return false;
      },
      selectRole: function selectRole() {
        this.get('popoverservice').openPopover({
          name: 'select-role',
          delegate: this,
          callback: 'setRoleToSelected',
          popoverClass: 'popover--floating'
        });
      },
      setRoleToSelected: function setRoleToSelected(privilegeLevel) {
        var _this$get;

        // We will get an array of all project members that needs to be updated
        (_this$get = this.get('items')) === null || _this$get === void 0 ? void 0 : _this$get.forEach(function (item) {
          if (item.customerAdmin) return; // We dont change privilege level for customer admins

          for (var projectId in item.projects) {
            var member = item.projects[projectId];
            if (!member) continue;
            member.set('privilegeLevel', privilegeLevel);
          }
        });
        this.set('cache', new Date());
      },
      removeSelectedFromProjects: function removeSelectedFromProjects() {
        if (confirm('Are you sure you want to remove the user from all projects?')) {
          this.send('setRoleToSelected', 0);
        }

        return false;
      },
      deleteSelected: function deleteSelected() {
        if (!this.get('items.length')) return;
        this.get('items').forEach(function (item) {
          // We dont delete customer admins
          if (item.customerAdmin) return;
        });
        this.get('popoverservice').openPopover({
          name: 'delete-user-info',
          data: this.get('items').mapBy('user'),
          popoverClass: 'popover--floating'
        });
      },
      onChangedOrganization: function onChangedOrganization(organization) {
        this.set('customerProfile.userOrganization', organization);
        return false;
      },
      queryOrganizations: function queryOrganizations(_term) {
        var _this$get2;

        var term = _term || '';
        var results = [];
        (_this$get2 = this.get('organizations')) === null || _this$get2 === void 0 ? void 0 : _this$get2.forEach(function (org) {
          var name = org.get('name') || '';
          var matchingSearch = name.toLowerCase().indexOf(term.toLowerCase()) !== -1;

          if (matchingSearch) {
            results.pushObject(org);
          }
        });
        return results;
      },
      changedPrivilegeLevel: function changedPrivilegeLevel(newProjMember) {
        var _this3 = this;

        var projectId = newProjMember.get('project.id');
        var privilegeLevel = newProjMember.get('privilegeLevel'); // We need to update on a multi item edit all records related to the project
        // For single item, the project member record is already updated

        if (this.get('multiItem')) {
          this.get('items').forEach(function (item) {
            var projMember = item.projects[projectId];

            if (!projMember) {
              // If no project member we need to invite first the user
              var invite = _this3.store.createRecord('projectMember', {
                user: item.user,
                project: _this3.store.peekRecord('project', projectId),
                privilegeLevel: privilegeLevel,
                ssoProviders: []
              });

              item.projects[projectId] = invite;
            } else {
              if (projMember.get('privilegeLevel') !== privilegeLevel) {
                projMember.set('privilegeLevel', privilegeLevel);
              }
            }
          });
        }
      },
      changedExpirationDate: function changedExpirationDate(newProjMember) {
        var projectId = newProjMember.get('project.id');
        var expDate = newProjMember.get('user.expirationDate'); // We need to update on a multi item edit all guests related to the project
        // For single item, the project member record is already updated

        if (this.get('multiItem')) {
          this.get('items').forEach(function (item) {
            var projMember = item.projects[projectId];

            if (projMember.get('privilegeLevel') == _utils.default.privilegeLevels.guest) {
              projMember.set('user.expirationDate', expDate);
            }
          });
        }
      },
      changedSSOproviders: function changedSSOproviders(newProjMember) {
        var projectId = newProjMember.get('project.id');
        var ssoProviders = newProjMember.get('ssoProviders'); // We need to update on a multi item edit all guests related to the project
        // For single item, the project member record is already updated

        if (this.get('multiItem')) {
          this.get('items').forEach(function (item) {
            var projMember = item.projects[projectId];

            if (projMember) {
              projMember.set('ssoProviders', ssoProviders);
            }
          });
        }
      },
      inviteUsers: function inviteUsers(projectItem) {
        var _this4 = this;

        var projectId = projectItem.id;
        this.get('items').forEach(function (item) {
          var projMember = item.projects[projectId];

          if (!projMember) {
            // If no project member we need to invite first the user
            var invite = _this4.store.createRecord('projectMember', {
              user: item.user,
              project: _this4.store.peekRecord('project', projectId),
              privilegeLevel: _utils.default.privilegeLevels.member,
              ssoProviders: []
            });

            item.projects[projectId] = invite;
          }
        });

        if (this.get('multiItem')) {
          projectItem.record = Ember.Object.create({
            privilegeLevel: _utils.default.privilegeLevels.member,
            ssoProvidersString: '',
            user: {
              expirationDate: null
            },
            project: {
              id: projectId
            }
          });
        } else {
          projectItem.record = this.get('items.0').projects[projectId];
        }
      }
    }
  });

  _exports.default = _default;
});