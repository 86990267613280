define("mgw/components/users-grid", ["exports", "ember-local-storage", "mgw/misc/utils"], function (_exports, _emberLocalStorage, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var $ = window.$;
  var Slick = window.Slick;

  var lowerCaseMismatch = function lowerCaseMismatch(itemValue, filterValue) {
    return itemValue.toLowerCase().indexOf(filterValue.toLowerCase()) == -1;
  };

  var isMatch = function isMatch(itemValue, filterValue) {
    if (itemValue == null) {
      return false;
    } else if (filterValue && typeof itemValue === "string" && lowerCaseMismatch(itemValue, filterValue)) {
      return false;
    } else if (typeof itemValue === "number" && itemValue != parseInt(filterValue)) {
      return false;
    } else if (typeof itemValue === "boolean" && (itemValue + "").indexOf(filterValue) == -1) {
      return false;
    }

    return true;
  };

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    popoverservice: Ember.inject.service(),
    tagName: "ul",
    columnSettingsKey: 'usersGridColumns',
    columnSettingsVersion: 1,
    // input
    items: null,
    columns: [],
    cache: null,
    searchStr: '',
    searchProjectsStr: '',
    roleFilterValue: -1,
    fillSpace: false,
    filteredColumns: Ember.computed('columns', 'searchProjectsStr', function () {
      var _this = this;

      if (!this.get('searchProjectsStr.length')) return this.get('columns');
      return this.get('columns').filter(function (column) {
        if (column.type !== 'project') return true;
        return column.name.toLowerCase().indexOf(_this.get('searchProjectsStr').toLowerCase()) > -1;
      });
    }),
    sortByCol: Ember.computed("cache", function () {
      var storedColumn = this.get('settings.' + this.columnSettingsKey + 'SortByCol');
      return storedColumn ? storedColumn : {
        field: 'id'
      };
    }),
    sortIsAsc: Ember.computed("cache", function () {
      var sortIsAsc = this.get('settings.' + this.columnSettingsKey + 'SortIsAsc');
      return sortIsAsc ? true : false;
    }),
    sortedItems: Ember.computed("items", "sortByCol", "sortIsAsc", "cache", function () {
      var _this2 = this;

      var sort = this.items.sort(function (a, b) {
        var _a$projects$_this2$so, _b$projects$_this2$so, _a$record, _a$record2, _a$record2$get, _b$record, _b$record2, _b$record2$get, _a$record3, _b$record3, _a$customerProfile, _a$user, _b$customerProfile, _b$user, _a$record4, _b$record4;

        var aValue, bValue;
        var value = 0;

        switch (_this2.sortByCol.type) {
          case 'project':
            aValue = a.projects[_this2.sortByCol.field] ? (_a$projects$_this2$so = a.projects[_this2.sortByCol.field]) === null || _a$projects$_this2$so === void 0 ? void 0 : _a$projects$_this2$so.get('privilegeLevel') : -1;
            bValue = b.projects[_this2.sortByCol.field] ? (_b$projects$_this2$so = b.projects[_this2.sortByCol.field]) === null || _b$projects$_this2$so === void 0 ? void 0 : _b$projects$_this2$so.get('privilegeLevel') : -1;
            value = aValue - bValue;
            break;

          case 'date':
            aValue = ((_a$record = a.record) === null || _a$record === void 0 ? void 0 : _a$record.get('privilegeLevel')) == 5 && ((_a$record2 = a.record) === null || _a$record2 === void 0 ? void 0 : (_a$record2$get = _a$record2.get(_this2.sortByCol.field)) === null || _a$record2$get === void 0 ? void 0 : _a$record2$get.getTime()) || 0;
            bValue = ((_b$record = b.record) === null || _b$record === void 0 ? void 0 : _b$record.get('privilegeLevel')) == 5 && ((_b$record2 = b.record) === null || _b$record2 === void 0 ? void 0 : (_b$record2$get = _b$record2.get(_this2.sortByCol.field)) === null || _b$record2$get === void 0 ? void 0 : _b$record2$get.getTime()) || 0;
            value = aValue - bValue;
            break;

          case 'number':
            aValue = a.record ? (_a$record3 = a.record) === null || _a$record3 === void 0 ? void 0 : _a$record3.get(_this2.sortByCol.field) : -1;
            if (isNaN(aValue)) aValue = -2;
            bValue = b.record ? (_b$record3 = b.record) === null || _b$record3 === void 0 ? void 0 : _b$record3.get(_this2.sortByCol.field) : -1;
            if (isNaN(bValue)) bValue = -2;
            value = aValue - bValue;
            break;

          case 'globalOrCustomer':
            aValue = ((_a$customerProfile = a.customerProfile) === null || _a$customerProfile === void 0 ? void 0 : _a$customerProfile.get(_this2.sortByCol.field)) || ((_a$user = a.user) === null || _a$user === void 0 ? void 0 : _a$user.get(_this2.sortByCol.field)) || '';
            bValue = ((_b$customerProfile = b.customerProfile) === null || _b$customerProfile === void 0 ? void 0 : _b$customerProfile.get(_this2.sortByCol.field)) || ((_b$user = b.user) === null || _b$user === void 0 ? void 0 : _b$user.get(_this2.sortByCol.field)) || '';
            value = aValue.localeCompare(bValue);
            break;

          default:
            value = (((_a$record4 = a.record) === null || _a$record4 === void 0 ? void 0 : _a$record4.get(_this2.sortByCol.field)) || '').localeCompare(((_b$record4 = b.record) === null || _b$record4 === void 0 ? void 0 : _b$record4.get(_this2.sortByCol.field)) || '');
        }

        return value || a.defaultSortKey.localeCompare(b.defaultSortKey); // If same, we just compare by id
      });
      if (this.sortIsAsc) return sort;
      return sort.reverse();
    }),
    observeItems: Ember.observer("items", function () {
      this.refreshGrid(true);
    }),
    observeCache: Ember.observer("cache", "searchStr", "roleFilterValue", function () {
      Ember.run.debounce(this, this.refreshGrid, 300);
    }),
    observeFilteredColumns: Ember.observer("searchProjectsStr", function () {
      Ember.run.debounce(this, this.refreshColumns, 300);
    }),
    dataView: null,
    grid: null,
    showModal: false,
    selectedProject: null,
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.setup);
      window.enableJqueryDrag();
    },
    defaultFormatter: function defaultFormatter(row, cell, value, columnDef, dataContext) {
      var _dataContext$record;

      return value || ((_dataContext$record = dataContext.record) === null || _dataContext$record === void 0 ? void 0 : _dataContext$record.get(columnDef.field)) || '';
    },
    strFilterFunc: function strFilterFunc(item, field, projectColumn) {
      if (field !== undefined) {
        var value;

        if (projectColumn) {
          value = item.projects[field] ? this.intl.t(_utils.default.getPrivilegeLevelKeyText(item.projects[field].get('privilegeLevel'))) : '';
        } else {
          value = item.customerProfile.get(field) || item.user.get(field);
        }

        return !this.searchStr || !this.searchStr.length || isMatch(value, this.searchStr);
      } else return false;
    },
    roleFilterFunc: function roleFilterFunc(item, field, projectColumn) {
      if (field !== undefined) {
        return this.roleFilterValue == -1 || projectColumn && item.projects[field] && item.projects[field].get('privilegeLevel') == this.roleFilterValue;
      } else return false;
    },
    defaultFilter: function defaultFilter(item) {
      if ((!this.searchStr || !this.searchStr.length) && this.roleFilterValue == -1) return true;
      var passStrFilter = false;
      var passRoleFilter = false;
      passStrFilter = this.strFilterFunc(item, 'fullName');

      var _iterator = _createForOfIteratorHelper(this.columns),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var column = _step.value;

          if (!passStrFilter && this.strFilterFunc(item, column.field, column.type == 'project')) {
            passStrFilter = true;
          }

          if (!passRoleFilter && this.roleFilterFunc(item, column.field, column.type == 'project')) {
            passRoleFilter = true;
          }

          if (passStrFilter && passRoleFilter) break;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return passStrFilter && passRoleFilter;
    },
    refreshGrid: function refreshGrid() {
      var updateColumns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.dataView.beginUpdate();
      this.dataView.setItems(this.sortedItems.toArray());
      this.dataView.endUpdate();
      this.grid.invalidateAllRows();
      if (updateColumns) this.grid.setColumns(this.get('filteredColumns'));
      this.grid.render();
    },
    refreshColumns: function refreshColumns() {
      this.grid.setColumns(this.get('filteredColumns'));
    },
    saveColumnSettings: function saveColumnSettings() {
      this.set('settings.' + this.columnSettingsKey, this.get("grid").getColumns());
      this.set('settings.' + this.columnSettingsKey + 'Version', this.columnSettingsVersion);
    },
    setup: function setup() {
      var _this3 = this;

      var self = this;
      var options = {
        enableCellNavigation: true,
        explicitInitialization: true,
        enableColumnReorder: true,
        defaultFormatter: this.defaultFormatter,
        forceSyncScrolling: true,
        defaultColumnWidth: 200,
        enableAsyncPostRender: true,
        asyncPostRenderDelay: 0,
        fullWidthRows: true,
        forceFitColumns: this.get('fillSpace'),
        rowHeight: 40
      };
      this.set("dataView", new Slick.Data.DataView());
      this.set("grid", new Slick.Grid("#".concat(this.elementId, " .slick-container"), this.dataView, this.filteredColumns, options));
      this.grid.setSortColumn(this.sortByCol.field, this.sortIsAsc);
      this.grid.onCellChange.subscribe(function (e, args) {
        _this3.dataView.updateItem(args.item.id, args.item);
      });
      this.dataView.onRowCountChanged.subscribe(function () {
        _this3.grid.updateRowCount();

        _this3.grid.render();
      });
      this.dataView.onRowsChanged.subscribe(function (e, args) {
        _this3.grid.invalidateRows(args.rows);

        _this3.grid.render();
      });
      this.grid.onColumnsResized.subscribe(function () {
        _this3.saveColumnSettings();
      });
      this.grid.onColumnsReordered.subscribe(function () {
        _this3.saveColumnSettings();
      });
      this.grid.onSort.subscribe(function (e, args) {
        _this3.set('settings.' + _this3.columnSettingsKey + 'SortByCol', args.sortCol);

        _this3.set('settings.' + _this3.columnSettingsKey + 'SortIsAsc', args.sortAsc);

        _this3.set('cache', new Date());
      });
      this.grid.onClick.subscribe(function (e, args) {
        // If we click on a privilege btn we call to the edit options
        if (e.target.classList.contains("privilege-btn") && !e.target.classList.contains("privilege-25")) {
          var elementId = $(e.target).attr("id");
          var dataView = args.grid.getData();
          var item = dataView.getItem(args.row);
          var column = args.grid.getColumns()[args.cell];
          var memberRecord = item.record || item.projects[column.field];
          self.editPrivilegeLevel(memberRecord, elementId, args.row, column.type == 'project');
          return;
        } // If we click on a invite btn


        if (e.target.classList.contains("invite-btn")) {
          var _dataView = args.grid.getData();

          var _item = _dataView.getItem(args.row);

          var _column = args.grid.getColumns()[args.cell];

          if (_column.type == 'project') {
            var user = _item.user.content || _item.user;

            var project = _this3.store.peekRecord('project', _column.id);

            self.inviteUser(user, project, args.row);
          } else {
            self.inviteUsers(_item, args.row);
          }

          return;
        } // If we click on a expiration btn


        if (e.target.classList.contains("expdate-btn")) {
          var _elementId = $(e.target).attr("id");

          var _dataView2 = args.grid.getData();

          var _item2 = _dataView2.getItem(args.row);

          var _column2 = args.grid.getColumns()[args.cell];

          var _memberRecord = _item2.record || _item2.projects[_column2.field];

          self.editExpirationDate(_memberRecord, _elementId, args.row);
          return;
        } // If we click on a sso providers btn


        if (e.target.classList.contains("ssoprovider-btn")) {
          var _elementId2 = $(e.target).attr("id");

          var _dataView3 = args.grid.getData();

          var _item3 = _dataView3.getItem(args.row);

          var _column3 = args.grid.getColumns()[args.cell];

          var _memberRecord2 = _item3.record || _item3.projects[_column3.field];

          self.editSSOProviders(_memberRecord2, _elementId2, args.row);
          return;
        }
      });
      this.grid.onDblClick.subscribe(function (e, args) {
        var dataView = args.grid.getData();
        var item = dataView.getItem(args.row);
        if (_this3.editProfile) _this3.editProfile(item);
        return;
      });
      var rowSelectionModel = new Slick.RowSelectionModel();
      rowSelectionModel.onSelectedRangesChanged.subscribe(function (e, args) {
        var selectedItems = [];

        if (args.length) {
          args.forEach(function (range) {
            var item = _this3.get('dataView').getItem(range.fromRow);

            if (item) selectedItems.push(item);
          });
        }

        if (_this3.didSelectItems) _this3.didSelectItems(selectedItems);
      });
      this.set('rowSelectionModel', rowSelectionModel);
      this.get('grid').setSelectionModel(rowSelectionModel);
      this.grid.init();
      this.dataView.beginUpdate();
      this.dataView.setItems(this.sortedItems.toArray());
      this.dataView.setFilter(this.defaultFilter.bind(this));
      this.dataView.setFilterArgs({
        searchStr: this.get('searchStr'),
        roleFilterValue: this.get('searchStr')
      });
      this.dataView.endUpdate();
      this.grid.resizeCanvas();
    },
    editPrivilegeLevel: function editPrivilegeLevel(record, elementId, row, fullEdit) {
      if (record) {
        this.get('popoverservice').openPopover({
          name: 'member-options',
          data: record,
          callback: 'selectedPrivilegeLevel',
          delegate: this,
          popoverClass: 'popover--floating',
          sender: elementId,
          options: {
            context: row,
            showRoles: true,
            showExpDate: fullEdit,
            showSSOProviders: fullEdit
          }
        });
      }

      return false;
    },
    editExpirationDate: function editExpirationDate(record, elementId, row) {
      if (record) {
        this.get('popoverservice').openPopover({
          name: 'member-options',
          data: record,
          callback: 'selectedExpirationDate',
          delegate: this,
          popoverClass: 'popover--floating',
          sender: elementId,
          options: {
            context: row,
            showRoles: false,
            showExpDate: true,
            showSSOProviders: false
          }
        });
      }

      return false;
    },
    editSSOProviders: function editSSOProviders(record, elementId, row) {
      if (record) {
        this.get('popoverservice').openPopover({
          name: 'member-options',
          data: record,
          callback: 'selectedSSOProviders',
          delegate: this,
          popoverClass: 'popover--floating',
          sender: elementId,
          options: {
            context: row,
            showRoles: false,
            showExpDate: false,
            showSSOProviders: true
          }
        });
      }

      return false;
    },
    inviteUser: function inviteUser(user, project, row) {
      if (user && project) {
        this.get('popoverservice').openPopover({
          name: 'invite-user',
          data: {
            user: user,
            project: project
          },
          options: {
            context: row
          },
          callback: 'invitedUser',
          delegate: this,
          popoverClass: 'popover--floating'
        });
      }

      return false;
    },
    inviteUsers: function inviteUsers(item, row) {
      if (this.onInviteUsers) {
        this.onInviteUsers(item);
        this.get('grid').invalidateRows([row]);
        this.get('grid').render();
      }
    },
    willDestroyElement: function willDestroyElement() {
      window.disableJqueryDrag();
      this.grid.destroy();
      this.set("grid", null);
      this.set("dataView", null);
    },
    actions: {
      invitedUser: function invitedUser(record, row) {
        var _this4 = this;

        var promise;

        if (record.get('type') == 'projectMember') {
          promise = Ember.RSVP.Promise.resolve(record);
        } else {
          // Invite
          promise = record.belongsTo('projectMember').load();
        }

        promise.then(function (projectMember) {
          var item = _this4.get('dataView').getItem(row);

          item.projects[projectMember.get('project.id')] = projectMember;

          _this4.get('grid').invalidateRows([row]);

          _this4.get('grid').render();
        });
      },
      selectedPrivilegeLevel: function selectedPrivilegeLevel(projectMember, row) {
        if (this.onChangedPrivilegeLevel) this.onChangedPrivilegeLevel(projectMember);
        this.get('grid').invalidateRows([row]);
        this.get('grid').render();
        return false;
      },
      selectedExpirationDate: function selectedExpirationDate(projectMember, row) {
        if (this.onChangedExpirationDate) this.onChangedExpirationDate(projectMember);
        this.get('grid').invalidateRows([row]);
        this.get('grid').render();
        return false;
      },
      selectedSSOProviders: function selectedSSOProviders(projectMember, row) {
        if (this.onChangeSSOProviders) this.onChangeSSOProviders(projectMember);
        this.get('grid').invalidateRows([row]);
        this.get('grid').render();
        return false;
      }
    }
  });

  _exports.default = _default;
});