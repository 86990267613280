define("mgw/routes/federation-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    initialStep: null,
    steps: {
      waitingForAction: 'WAITING_FOR_ACTION',
      linkingToExistingUser: 'LINKING_TO_EXISTING_USER',
      linkingToExistingUserSuccess: 'LINKING_TO_EXISTING_USER_SUCCESS',
      linkingToExistingUserFailed: 'LINKING_TO_EXISTING_USER_FAILED',
      creatingNewUser: 'CREATING_NEW_USER',
      creatingNewUserFailed: 'CREATING_NEW_USER_FAILED'
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var params = transition.to.queryParams || {};
      this.set('provider', params.state);
      this.set('initialStep', this.steps.waitingForAction);
      return new Ember.RSVP.Promise(function (resolve) {
        var _this$session$data, _this$session$data$au, _this$currentUser$use, _this$session$data2, _this$session$data2$a, _this$currentUser$use2;

        var alreadyLoggedInWithCognitoAs = ((_this$session$data = _this.session.data) === null || _this$session$data === void 0 ? void 0 : (_this$session$data$au = _this$session$data.authenticated) === null || _this$session$data$au === void 0 ? void 0 : _this$session$data$au.authenticator) == "authenticator:cognito" && ((_this$currentUser$use = _this.currentUser.user) === null || _this$currentUser$use === void 0 ? void 0 : _this$currentUser$use.id);
        var alreadyLoggedInWithFederationAs = ((_this$session$data2 = _this.session.data) === null || _this$session$data2 === void 0 ? void 0 : (_this$session$data2$a = _this$session$data2.authenticated) === null || _this$session$data2$a === void 0 ? void 0 : _this$session$data2$a.authenticator) == "authenticator:cognito-federation" && ((_this$currentUser$use2 = _this.currentUser.user) === null || _this$currentUser$use2 === void 0 ? void 0 : _this$currentUser$use2.id);

        _this.session.authenticate('authenticator:cognito-federation', params.code, params.state).then(function () {
          if (alreadyLoggedInWithCognitoAs) {
            // Existing StreamBIM user linked to external identity
            _this.set('initialStep', _this.steps.linkingToExistingUserSuccess);

            resolve();
          } else if (alreadyLoggedInWithFederationAs) {
            _this.currentUser.load().then(function () {
              resolve(_this.transitionTo('index')); // TODO: next parameter
            });
          } else {
            resolve(_this.transitionTo('index')); // TODO: next parameter
          }
        }).catch(function (err) {
          if (err.status == 449) {
            // User needs to confirm unknown email
            _this.session.authenticate('authenticator:cognito-federation-existing', err.payload.RefreshToken, params.state).then(function () {
              if (err.payload.ProposedUsername) {
                _this.set('proposedUsername', err.payload.ProposedUsername);
              } else {
                _this.step = _this.steps.creatingNewUser;
              }

              resolve();
            });
          } else {
            // Other error
            _this.set('error', err);

            resolve();
          }
        });
      });
    },
    setupController: function setupController(controller) {
      controller.set('error', this.error);
      controller.set('email', this.proposedUsername);
      controller.set('initialProposedUsername', this.proposedUsername);
      controller.set('provider', this.provider);
      controller.set('currentStep', this.initialStep);
      controller.set('steps', this.steps);

      this._super(controller);
    }
  });

  _exports.default = _default;
});