define("mgw/models/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    _pinnedRelationships: null,
    type: Ember.computed('constructor.modelName', function () {
      return Ember.String.camelize(this.get('constructor.modelName'));
    }),
    save: function save(options) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this._super(options).then(function (result) {
          return resolve(result);
        }).catch(function (error) {
          return _this.reload().finally(function () {
            _this.rollbackAttributes();

            return reject(error);
          });
        });
      });
    },
    pinRelationships: function pinRelationships() {
      var _this2 = this;

      var pinnedRelationships = {};
      this.eachRelationship(function (name, descriptor) {
        var _this2$get;

        switch (descriptor.kind) {
          case 'hasMany':
            return pinnedRelationships[name] = _this2.get(name), function (x) {
              return x.toArray() || [];
            };

          case 'belongsTo':
            return pinnedRelationships[name] = ((_this2$get = _this2.get(name)) === null || _this2$get === void 0 ? void 0 : _this2$get.content) || _this2.get(name) || null;
        }
      });
      return this.set('_pinnedRelationships', pinnedRelationships);
    },
    rollbackRelationships: function rollbackRelationships() {
      var _this3 = this;

      if (!this._pinnedRelationships) {
        return;
      }

      this.eachRelationship(function (name, descriptor) {
        if (descriptor.kind === 'hasMany') {
          return _this3.get(name).setObjects(_this3.get("_pinnedRelationships.".concat(name)).toArray() || []);
        }

        return _this3.set(name, _this3.get("_pinnedRelationships.".concat(name)) || null);
      });
    },
    rollbackAttributes: function rollbackAttributes() {
      this.rollbackRelationships();
      return this._super();
    },
    didCreate: function didCreate() {
      this.pinRelationships();
      return this._super();
    },
    didUpdate: function didUpdate() {
      this.pinRelationships();
      return this._super();
    }
  });

  _exports.default = _default;
});