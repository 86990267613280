define("mgw/controllers/federation-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    cognito: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    ssoproviderservice: Ember.inject.service(),
    initialProposedUsername: null,
    needsToConfirmUsername: false,
    provider: null,
    emailNeedsToBeVerified: null,
    linkExistingUserToExternalProvider: false,
    email: null,
    password: null,
    currentStep: null,
    providerInfo: Ember.computed('provider', 'ssoproviderservice', function () {
      if (this.get('provider')) {
        return this.get('ssoproviderservice').getProviderInfo(this.get('provider'));
      }
    }),
    actions: {
      backToLogin: function backToLogin() {
        this.send('logout');
        window.location.href = new URL('/login', window.location.origin).toString();
      },
      setCurrentStep: function setCurrentStep(step) {
        this.set('currentStep', step);
      },
      linkToExistingUser: function linkToExistingUser() {
        var _this = this;

        // First invalidate current session before authenticating a new session
        this.set('session.dontReloadOnInvalidation', true);
        this.session.invalidate().then(Ember.run.next(function () {
          _this.session.authenticate('authenticator:cognito', {
            username: _this.email,
            password: _this.password
          }).then(function () {
            /* eslint-disable-next-line prefer-const */
            var redirectUrl = new URL('/mgw/api/v2/federation/redirecttoexternallogin?provider=' + _this.provider.toUpperCase(), window.location.origin);
            window.location.href = redirectUrl.toString();
          }, function (err) {
            if (err.code === "password_reset_needed") {
              _this.router.transitionTo('invalid-existing-password', {
                queryParams: {
                  email: _this.email
                }
              });
            } else {
              _this.set('currentStep', _this.steps.linkingToExistingUserFailed);
            }
          });
        }));
      },
      confirmEmail: function confirmEmail() {
        var _this2 = this;

        if (!this.proposedUsername) {
          return;
        }

        this.set('isPerformingRequest', true);
        this.set('usernameError', null);
        this.cognito.createFederated(this.provider, this.session.data.authenticated.idToken, this.proposedUsername).then(function () {
          _this2.set('isPerformingRequest', false);

          _this2.set('session.data.authenticated.usernamePending', false);

          var authInLocalStorage = JSON.parse(window.localStorage.getItem('ember_simple_auth-session'));
          delete authInLocalStorage.authenticated.usernamePending;
          window.localStorage.setItem('ember_simple_auth-session', JSON.stringify(authInLocalStorage));

          if (_this2.get('initialProposedUsername') == _this2.get('proposedUsername')) {
            // Email is verified by external provider - user ready to be loaded and profile completed
            _this2.currentUser.load().then(function () {
              _this2.transitionToRoute('user-profile');
            });
          } else {
            // needs to verify email and clear localstorage to get new token
            window.localStorage.setItem('ember_simple_auth-session', JSON.stringify({
              "authenticated": {}
            })); // Yes, this is hacky, but calling invalidate session redirects to login screen...

            _this2.set('emailNeedsToBeVerified', true);
          }
        }).catch(function (err) {
          var _err$responseJSON;

          _this2.set('isPerformingRequest', false);

          _this2.set('usernameError', (err === null || err === void 0 ? void 0 : (_err$responseJSON = err.responseJSON) === null || _err$responseJSON === void 0 ? void 0 : _err$responseJSON.error) || JSON.stringify((err === null || err === void 0 ? void 0 : err.responseJSON) || err || 'unknown error'));
        });
      },
      createNewUser: function createNewUser() {
        var _this3 = this;

        if (!this.email) {
          this.set('currentStep', this.steps.creatingNewUser);
          return;
        }

        this.set('usernameError', null);
        this.set('isPerformingRequest', true);
        this.cognito.createFederated(this.provider, this.session.data.authenticated.idToken, this.email).then(function () {
          _this3.set('isPerformingRequest', false);

          _this3.set('session.data.authenticated.usernamePending', false);

          var authInLocalStorage = JSON.parse(window.localStorage.getItem('ember_simple_auth-session'));
          delete authInLocalStorage.authenticated.usernamePending;
          window.localStorage.setItem('ember_simple_auth-session', JSON.stringify(authInLocalStorage));

          if (_this3.get('initialProposedUsername') == _this3.get('email')) {
            // Email is verified by external provider - user ready to be loaded and profile completed
            _this3.currentUser.load().then(function () {
              _this3.router.transitionTo('user-profile');
            });
          } else {
            // needs to verify email and clear localstorage to get new token
            window.localStorage.setItem('ember_simple_auth-session', JSON.stringify({
              "authenticated": {}
            })); // Yes, this is hacky, but calling invalidate session redirects to login screen...

            _this3.set('emailNeedsToBeVerified', true);
          }
        }).catch(function (err) {
          _this3.set('isPerformingRequest', false);

          if (err.status == 409) {
            // Email is linked to an existing StreamBIM user
            _this3.set('currentStep', _this3.steps.creatingNewUserFailed);
          } else {
            var _err$responseJSON2;

            _this3.set('usernameError', (err === null || err === void 0 ? void 0 : (_err$responseJSON2 = err.responseJSON) === null || _err$responseJSON2 === void 0 ? void 0 : _err$responseJSON2.error) || JSON.stringify((err === null || err === void 0 ? void 0 : err.responseJSON) || err || 'unknown error'));
          }
        });
      }
    }
  });

  _exports.default = _default;
});