define("mgw/components/profile-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: 'profile--field',
    items: null,
    fieldName: null,
    organizations: null,
    _value: null,
    _placeholder: null,
    customerValues: 0,
    userValues: 0,
    iconName: Ember.computed('_value', 'customerValues', 'userValues', function () {
      if (this.get('_value') || !this.get('userValues')) return 'icon--organization';
      if (!this.get('customerValues')) return 'icon--user-blue';
      return 'icon--org-user';
    }),
    valueObserver: Ember.observer('_value', function () {
      var _this = this;

      this.get('items').forEach(function (item) {
        if (item.customerProfile.get(_this.fieldName) !== _this.get('_value')) {
          item.customerProfile.set(_this.fieldName, _this.get('_value'));
        }
      });
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      var customerValues = new Set();
      var firstCustomerValue = null;
      var userValues = new Set();
      var firstUserValue = null;
      var userPlaceholders = new Set();
      var firstPlaceholder = null;
      this.get('items').forEach(function (item) {
        var userValue = _this2.fieldName == 'userOrganization' ? item.user.get('organizationName') : item.user.get(_this2.fieldName);
        userPlaceholders.add(userValue);
        if (!firstPlaceholder) firstPlaceholder = userValue;

        if (_this2.fieldName == 'userOrganization' && item.customerProfile.get('userOrganization.id') || item.customerProfile.get(_this2.fieldName)) {
          var customerValue = item.customerProfile.get(_this2.fieldName);
          customerValues.add(customerValue);
          if (!firstCustomerValue) firstCustomerValue = customerValue;
        } else {
          userValues.add(userValue);
          if (!firstUserValue) firstUserValue = userValue;
        }
      }); // We dont have a uniq customer value for all, so we use the placeholder

      if (userValues.size || customerValues.size > 1) {
        this.set('_value', null);
        if (!userValues.size) this.set('_placeholder', this.intl.t('multipleCompanyValues'));
        if (!customerValues.size) this.set('_placeholder', userValues.size == 1 ? firstUserValue : this.intl.t('multiplePersonalValues'));
        if (userValues.size && customerValues.size) this.set('_placeholder', this.intl.t('mixedCompanyandPersonalValues'));
      } else {
        // Single case where we show a value, is if all users have the same value in field
        this.set('_value', firstCustomerValue);
        this.set('_placeholder', userPlaceholders.size == 1 ? firstPlaceholder : this.intl.t('multiplePersonalValues'));
      }

      this.set('customerValues', customerValues.size);
      this.set('userValues', userValues.size);
    },
    actions: {
      deleteField: function deleteField() {
        this.set('_value', null);
        return false;
      },
      onChangedOption: function onChangedOption(option) {
        this.set('_value', option);
      },
      queryOrgs: function queryOrgs(_term) {
        var _this$get;

        var term = _term || '';
        var results = [];
        (_this$get = this.get('organizations')) === null || _this$get === void 0 ? void 0 : _this$get.forEach(function (org) {
          var name = org.get('name') || '';
          var matchingSearch = name.toLowerCase().indexOf(term.toLowerCase()) !== -1;

          if (matchingSearch) {
            results.pushObject(org);
          }
        });
        return results;
      }
    }
  });

  _exports.default = _default;
});